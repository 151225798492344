import { RavenOptions } from 'raven-js';
import * as Raven from 'raven-js';

export const shouldIncludeUserAgent = (userAgent: string): boolean => {
  const isOldIe = /MSIE [56789]\./.test(userAgent);
  const isOldAndroid = /Android [234]\.]/.test(userAgent) && !/Chrome/.test(userAgent);
  return !(isOldIe || isOldAndroid);
};

export const initRaven = () => {
  if (!window.CONFIG.sentryDsn) {
    return;
  }
  const sentryConfig: RavenOptions = {
    tags: {
      environment: window.CONFIG.stage,
    },
    whitelistUrls: [/\.finnair\.com/],
    includePaths: [/^https:\/\/\w+\.finnair\.com/],
    sanitizeKeys: ['access_token', 'Authorization'],
    captureUnhandledRejections: true,
    shouldSendCallback: (): boolean => shouldIncludeUserAgent(window.navigator.userAgent),
  };
  Raven.config(window.CONFIG.sentryDsn, sentryConfig)
    .addPlugin(require('raven-js/plugins/console'), console, {
      levels: ['error'],
    })
    .install();
};
